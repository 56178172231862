export const routes = {
  home: '/',
  livescores: '/livescores',
  odds: '/odds',
  schedule: '/schedule',
  room: '/room/:idMatch',
  pageNotFound: '/pageNotFound',
  highlight: '/highlight',
  highlightDetail: '/highlight/:slugURL',
  embed: '/embed',
  apllyJob: '/ung-tuyen-binh-luan-vien',
  promotions: '/promotions',
  mys: '/mys',
  embedChat: '/embed-chat/:idMatch',

  ABOUTUS: '/ve-chung-toi',
  TERMSOFSERVICE: '/dieu-khoan-dich-vu',
  PRIVACYPOLICY: '/chinh-sach-bao-mat',
  CONTACT: '/lien-he',
  COPYRIGHT: '/ban-quyen',
  DISCLAIMER: '/mien-tru-trach-nhiem'
};
