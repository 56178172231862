import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import AppRoutes from './AppRoutes';
import Loading from 'components/loading';
import SEO from 'utils/common/seo';

function App() {
  console.log(
    '%cDeveloped by 👌OKVIP',
    'padding: 0.3rem 1.5rem; font-family: Roboto; font-size: 1.2em; line-height: 1.4em; color: white; background: rgb(131,58,180); background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);'
  );

  return (
    <BrowserRouter>
      <SEO />
      <Suspense fallback={<Loading showLoaderLine={false} />}>
        <AppRoutes />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
