import React from 'react';
import styles from './style.module.scss';
import logols from 'assets/images/logols.png';

export default function Loading({ hasContent, showLoaderLine }) {
  return (
    <div
      className={` flex items-center justify-center flex-col py-8 ${
        !hasContent && 'bg-[#0B1C4C] min-w-screen min-h-screen'
      } `}
    >
      <img
        src={logols}
        className={`object-contain ${hasContent ? 'w-44' : 'w-72 '}`}
        alt="LuongSon TV"
      />
      {showLoaderLine && <div className={styles.loaderLine}></div>}
    </div>
  );
}
