import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'styles/index.scss';
import { ConfigProvider } from 'antd';
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    prefixCls="luongson"
    theme={{
      token: {
        colorPrimary: '#feb307',
        borderRadius: 4
      },
      components: {
        Segmented: {
          itemActiveBg: '#feb307',
          itemSelectedBg: '#feb307',
          itemSelectedColor: '#ffffff'
        }
      }
    }}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </ConfigProvider>
);
