import { MAIN_URL } from 'helpers/config';
import React from 'react';
import { Helmet } from 'react-helmet';
import logo from 'assets/images/logols.png';

const desContent =
  'LuongSon TV - Điểm đến số 1 cho người yêu bóng đá. Xem trực tiếp các trận đấu nóng hổi trên toàn thế giới với chất lượng Full HD tốt nhất. Cung cấp thông tin tỷ số trực tiếp, tin tức nhanh chóng và bản tin bóng đá hàng ngày. Đừng bỏ lỡ cơ hội tham gia cộng đồng đam mê bóng đá tại luongson.tv!';

export default function SEO() {
  return (
    <Helmet>
      <title>LuongSon TV - Trực Tiếp Bóng Đá Miễn Phí</title>
      <meta name="description" content={desContent} />
      <meta
        name="robots"
        content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
      />
      <link rel="canonical" href={MAIN_URL} />
      <meta property="og:locale" content="vi_VN" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="LuongSon TV - Trực Tiếp Bóng Đá Miễn Phí" />
      <meta property="og:description" content={desContent} />
      <meta property="og:url" content={MAIN_URL} />
      <meta property="og:site_name" content="LuongSon TV" />
      <meta property="og:updated_time" content={new Date()} />
      <meta property="og:image" content={logo} />
      <meta property="og:image:secure_url" content={logo} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="400" />
      <meta property="og:image:alt" content="LuongSon TV - Trực Tiếp Bóng Đá Miễn Phí" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="LuongSon TV - Trực Tiếp Bóng Đá Miễn Phí" />
      <meta name="twitter:description" content={desContent} />
      <meta name="twitter:image" content={logo} />
    </Helmet>
  );
}
