import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from 'routers';

const BasicLayout = lazy(() => import('components/layouts/basic'));
const HomePage = lazy(() => import('pages/home'));
const LivescoresPage = lazy(() => import('pages/livescores'));
const OddsPage = lazy(() => import('pages/odds'));
const SchedulePage = lazy(() => import('pages/schedule'));
const RoomPage = lazy(() => import('pages/room'));
const NotFoundPage = lazy(() => import('pages/notFound'));
const Highlight = lazy(() => import('pages/highlight'));
const HighlightDetail = lazy(() => import('pages/highlightDetail'));
const Embed = lazy(() => import('pages/embed'));
const ApllyJob = lazy(() => import('pages/applyJob'));
const PromotionsPage = lazy(() => import('pages/promotions'));
const MysPage = lazy(() => import('pages/mys'));
const EmbedChatPage = lazy(() => import('pages/embedChat'));

const AboutUs = lazy(() => import('pages/footer/AboutUs'));
const TERMSOFSERVICE = lazy(() => import('pages/footer/Terms'));
const PRIVACYPOLICY = lazy(() => import('pages/footer/Privacy'));
const CONTACT = lazy(() => import('pages/footer/Contact'));
const COPYRIGHT = lazy(() => import('pages/footer/Copyright'));
const DISCLAIMER = lazy(() => import('pages/footer/Disclaimer'));

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<HomePage />} />
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.livescores} element={<LivescoresPage />} />
        <Route path={routes.odds} element={<OddsPage />} />
        <Route path={routes.schedule} element={<SchedulePage />} />
        <Route path={routes.room} element={<RoomPage />} />
        <Route path={routes.pageNotFound} element={<NotFoundPage />} />
        <Route path={routes.highlight} element={<Highlight />} />
        <Route path={routes.highlightDetail} element={<HighlightDetail />} />
        <Route path={routes.apllyJob} element={<ApllyJob />} />
        <Route path={routes.promotions} element={<PromotionsPage />} />
        <Route path={routes.mys} element={<MysPage />} />

        <Route path={routes.ABOUTUS} element={<AboutUs />} />
        <Route path={routes.CONTACT} element={<CONTACT />} />
        <Route path={routes.COPYRIGHT} element={<COPYRIGHT />} />
        <Route path={routes.DISCLAIMER} element={<DISCLAIMER />} />
        <Route path={routes.TERMSOFSERVICE} element={<TERMSOFSERVICE />} />
        <Route path={routes.PRIVACYPOLICY} element={<PRIVACYPOLICY />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path={routes.embed} element={<Embed />} />
      <Route path={routes.embedChat} element={<EmbedChatPage />} />
    </Routes>
  );
}
